import { Button, useToast } from "@chakra-ui/react"
import { PaymentOrder } from "../types/payment-order"
import { useAccount, useContractWrite, usePrepareContractWrite, useSwitchNetwork } from "wagmi"
import { CHAIN_ID, CLAIMER_ADDRESS } from "../config/constants"
import ClaimerBuild from '../abis/PeebleClaim.json'
import { useEffect } from "react"
import { doc, query, updateDoc } from "firebase/firestore"
import { firestore } from "../firebase"

export const ClaimOrder = (props: {order: PaymentOrder}) => {
    const {address, connector} = useAccount()
    const {switchNetwork} = useSwitchNetwork()
    const toast = useToast()
    const {config} = usePrepareContractWrite({
        address: CLAIMER_ADDRESS,
        abi: ClaimerBuild.abi,
        functionName: 'claim',
        args: [props.order.amount, props.order.nonce, props.order.signature]
    })

    const {data, write, isLoading} = useContractWrite(config)

    const updatePaymentOrder = async (txhash: string) => {
        const ref = doc(firestore, 'payment-orders', props.order.hash)
        await updateDoc(ref, {
            isUsed: true,
            txHash: txhash
        })
    }
    useEffect(() => {
        if(data?.hash) {
            updatePaymentOrder(data.hash)
            toast({
                status: 'success',
                title: 'Transaction sent to the blockchain',
                description: 'txhash: '+ data.hash,
                duration: 9000,
                isClosable: true
            })
        }
    }, [data])
    const claim = async () => {
        if(!connector) return
        const chainId = await connector.getChainId()
        if(chainId != CHAIN_ID) {
          if(switchNetwork) switchNetwork(CHAIN_ID)
          toast({
            status: 'warning',
            title: 'Switch to correct network before continue',
            description: 'Peebles living in the bsc',
            duration: 9000,
            isClosable: true
        })
        return
        }
        if(address?.toLowerCase() != props.order.address.toLowerCase()) {
            toast({
                status: 'error',
                title: 'Incompatible address',
                description: `The claim order was created using wallet ${props.order.address}. You need to use the same wallet to finalise the claim. `,
                duration: 9000,
                isClosable: true
            })
            return
        }
        if(write) write()
    }

    return (
        <Button 
        borderRadius={20}
        onClick={claim}
        isLoading={isLoading} colorScheme="pink">
            Claim
        </Button>
    )
}
import { SunIcon } from "@chakra-ui/icons"
import { VStack, HStack, Button, Text } from "@chakra-ui/react"
import { doc, getDoc, onSnapshot } from "firebase/firestore"
import { useEffect, useState } from "react"
import { firestore, service } from "../firebase"
import { Invite } from "../types/invite"

export const EarningsWithInvitation = (props: {inviteCode: string | undefined}) => {
    const [totalEarnings, setTotalEarnings] = useState(0)
    const [joinCounter, setJoinCounter] = useState(0)
    const [availableClaim, setAvailableClaim] = useState(0)
    const [alreadyClaimed, setAlreadyClaimed] = useState(0)
    const [loading, setLoading] = useState(false)

    const createPaymentOrder = async () => {
        setLoading(true)
        await service.hello()
        setLoading(false)
    }

    useEffect(() => {
        if(!props.inviteCode) return
        const inviteRef = doc(firestore, 'invites', props.inviteCode)
        const sub = onSnapshot(inviteRef, (snap) => {
            if(!snap.exists()) return
            const data = snap.data() as Invite
            setJoinCounter(data.usedByCounter)
            const totalEarnings = data.usedByCounter * 100
            const totalClaimed = (data.claimedCounter ?? 0) * 100
            setTotalEarnings(totalEarnings)
            setAvailableClaim(totalEarnings - (totalClaimed ?? 0))
            setAlreadyClaimed(totalClaimed ?? 0)
        })
    }, [props.inviteCode])

    return (
        <VStack w={'100%'}>
        <HStack wrap={'wrap'} w={'100%'} justifyContent={'space-between'}>
        <Text align={'center'} fontFamily={'gotham-rounded-bold'} color={'white'} fontSize={'medium'} fontWeight={'900'}>
            Referral Earnings
        </Text>

        <Text align={'center'} fontFamily={'gotham-rounded-bold'} color={'white'} fontSize={'large'} fontWeight={'900'}>
            {totalEarnings} $PEBs
        </Text>
        <HStack wrap={'wrap'} w={'100%'} justifyContent={'space-between'}>
        </HStack>
        <Text align={'center'} fontFamily={'gotham-rounded-bold'} color={'white'} fontSize={'medium'} fontWeight={'900'}>
            Nr. Users joined through referral
        </Text>

        <Text align={'center'} fontFamily={'gotham-rounded-bold'} color={'white'} fontSize={'large'} fontWeight={'900'}>
            {joinCounter - 1}
        </Text>
        </HStack>
        <HStack wrap={'wrap'} w={'100%'} justifyContent={'space-between'}>
        <Text align={'center'} fontFamily={'gotham-rounded-bold'} color={'white'} fontSize={'medium'} fontWeight={'900'}>
            Already claimed
        </Text>

        <Text align={'center'} fontFamily={'gotham-rounded-bold'} color={'white'} fontSize={'large'} fontWeight={'900'}>
            {alreadyClaimed} $PEBs
        </Text>
        </HStack>
        <HStack wrap={'wrap'} w={'100%'} justifyContent={'space-between'}>
        <Text align={'center'} fontFamily={'gotham-rounded-bold'} color={'white'} fontSize={'medium'} fontWeight={'900'}>
            Available to claim
        </Text>

        <Text align={'center'} fontFamily={'gotham-rounded-bold'} color={'white'} fontSize={'large'} fontWeight={'900'}>
            {availableClaim} $PEBs
        </Text>
        </HStack>
        <HStack wrap={'wrap'} w={'100%'} justifyContent={'flex-end'}>
            <Button
            borderRadius={20}
            isLoading={loading}
            onClick={createPaymentOrder}
            isDisabled={availableClaim <= 0}
            rightIcon={<SunIcon />}
            colorScheme="pink"
            >CLAIM $PEBs!</Button>
        </HStack>
    </VStack>
    )
}
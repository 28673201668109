import React, { useEffect, useState } from 'react';
import { ChakraProvider } from '@chakra-ui/react'
import LoginPage from './pages/login';
import { auth, firestore } from './firebase';
import { User, onAuthStateChanged } from 'firebase/auth';
import EnterInvitePage from './pages/enter-invite';
import { doc, getDoc } from 'firebase/firestore';
import HomePage from './pages/home';
import { LoadingPage } from './pages/loading';
import { WagmiConfig } from 'wagmi';
import { config } from './config/wagmi';
import Peeble from './assets/path_to_peeble.png'

const SubRouter = () => {
  const [dbUser, setDbUser] = useState(false)
  const [loading, setLoading] = useState(true)

  const getUser = async () => {
    const userUid = auth.currentUser?.uid ?? ""
    const snap = await getDoc(doc(firestore, 'users', userUid))
    if(snap.exists()) setDbUser(true)
    setLoading(false)
  }

  useEffect(() => {
    getUser()
  }, [])

  if(loading) return <LoadingPage />

  if(!dbUser) return <EnterInvitePage setDbUser={setDbUser} />

  return <HomePage />
}

const Router = () => {
  const [user, setUser] = useState<User | null>(null)

  onAuthStateChanged(auth, (user) => {
    if(!user) setUser(null)
    if(user) setUser(user)
  })

  if(!user) return <LoginPage />

  return <SubRouter />
}

function App() {
  useEffect(() => {
    const peeble = document.querySelector('.peeble') as any;
    let xPos = Math.random() * (window.innerWidth - peeble.width);
    let yPos = Math.random() * (window.innerHeight - peeble.height);
    let xDirection = 1;
    let yDirection = 1;
    let speed = 0.5; // Adjust speed as needed

    function animatePeeble() {
        if (xPos <= 0 || xPos + peeble.width >= window.innerWidth) {
            xDirection *= -1;
        }
        if (yPos <= 0 || yPos + peeble.height >= window.innerHeight) {
            yDirection *= -1;
        }
        xPos += speed * xDirection;
        yPos += speed * yDirection;
        peeble.style.left = `${xPos}px`;
        peeble.style.top = `${yPos}px`;
        requestAnimationFrame(animatePeeble);
    }

    const clickSounds = [
        "sounds/1.mp3",
        "sounds/4.mp3",
        "sounds/5.mp3",
        "sounds/h_1.mp3",
        "sounds/h_2.mp3",
    ];

    peeble.addEventListener('click', () => {
        const soundIndex = Math.floor(Math.random() * clickSounds.length);
        const audio = new Audio(clickSounds[soundIndex]);
        audio.volume = 0.1; // Set volume to 10%
        audio.play();
    });

    animatePeeble();
}, [])

  return (
    <WagmiConfig config={config}>
    <ChakraProvider>
    <img src={Peeble} className="peeble" alt="Peeble" />
      <Router />
    </ChakraProvider>
    </WagmiConfig>
  );
}

export default App;

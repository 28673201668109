import {initializeApp} from 'firebase/app'
import { getAnalytics } from "firebase/analytics";
import { getAuth } from 'firebase/auth'
import {getFirestore} from 'firebase/firestore'
import {getFunctions, connectFunctionsEmulator, httpsCallable} from 'firebase/functions'

const firebaseConfig = {
    apiKey: "AIzaSyC1GE337X37vOpQxZW1seYI4_zaVgVvVjA",
    authDomain: "peeble-f60be.firebaseapp.com",
    projectId: "peeble-f60be",
    storageBucket: "peeble-f60be.appspot.com",
    messagingSenderId: "948185194906",
    appId: "1:948185194906:web:a7aafe43d0c6f3c6ad9a23",
    measurementId: "G-K6GYWD3JZ7"
}

const app = initializeApp(firebaseConfig)
export const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const firestore = getFirestore();
const functions = getFunctions();
// connectFunctionsEmulator(functions, '0.0.0.0', 5001)
export const service = {
    hello: httpsCallable(functions, 'hello'),
    createUserByInvite:  httpsCallable(functions, 'createUserByInvite'),
    createUserInvite: httpsCallable(functions, 'createUserInvite')
}
export default app
import { useAccount, useSwitchNetwork, useWaitForTransaction } from "wagmi";
import { CHAIN_ID, FARM_V1_ADDRESS } from "../config/constants";
import { useApprove } from "../services/Peebles";
import { Button, useToast } from "@chakra-ui/react";

export const ApproveButton = (props: {value: number, balance: unknown}) => {
    const {connector} = useAccount()
    const {switchNetwork} = useSwitchNetwork()
    const { write: writeApprove, isLoading: isLoadingApprove, data: dataApprove } = useApprove({
        spender: FARM_V1_ADDRESS,
        value: (props.value * Math.pow(10, 18)).toString(),
      });
      const {isLoading: isLoadingApproveTransaction} = useWaitForTransaction({
        hash: dataApprove?.hash
      })
      const toast = useToast()
      const approve = async () => {
        if(!connector) return
        const chainId = await connector.getChainId()
        if(chainId != CHAIN_ID) {
          if(switchNetwork) switchNetwork(CHAIN_ID)
          toast({
            status: 'warning',
            title: 'Switch to correct network before continue',
            description: 'Peebles living in the arbitrum',
            duration: 9000,
            isClosable: true
        })
        return
        }
        if (writeApprove) writeApprove();
      }

    return (
        <Button
        borderRadius={20}
        isDisabled={props.value < 10 || props.value > Number((Number(props.balance) / Math.pow(10, 18)).toFixed(2))}
          onClick={approve}
          isLoading={isLoadingApprove || isLoadingApproveTransaction}
          w={"100%"}
          colorScheme="teal"
        >
          Allow ${props.value} PEB to Farm
        </Button>
    )
}
import { Box, Button, Card, CardBody, Flex, HStack, Image, Text, VStack, useToast } from "@chakra-ui/react"
import HiLogo from '../assets/Hi.png'
import XIcon from '../assets/xicon.svg'
import { GoogleAuthProvider, TwitterAuthProvider, signInWithPopup } from "firebase/auth";
import { useState } from "react";
import {auth} from '../firebase'
import GoogleIcon from '../assets/googleIcon.svg'

const LoginPage = () => {
    const [loading, setLoading] = useState(false)
    const toast = useToast()
    const provider = new TwitterAuthProvider();
    const googleProvider = new GoogleAuthProvider()

    const makeGoogleLogin = async () => {
        setLoading(true)
        try {
            await signInWithPopup(auth, googleProvider)
        } catch (error) {
            toast({
                title: 'Access denied',
                status: 'error',
                isClosable: true,
                duration: 9000
            })
        }
        setLoading(false)
    }

    const makeLogin = async () => {
        setLoading(true)
        try {
            await signInWithPopup(auth, provider)
        } catch (error) {
            toast({
                title: 'Access denied',
                status: 'error',
                isClosable: true,
                duration: 9000
            })
        }
        setLoading(false)
    }

    return (
        <VStack h="100vh" justifyContent={'center'}>
            <HStack  gap={'10px'} justifyContent={'center'} direction={'row'} wrap={'wrap'}>
                <Box w={'25em'} h={'25em'}>
                    <Image src={HiLogo} borderRadius={10}/>
                </Box>
                <Card bg="gray.800" w={'25em'} h={'25em'}>
                    <CardBody>
                <Flex direction={'column'} justifyContent={'space-between'} h={'100%'} borderRadius={10} >
                    <Text fontFamily={'gotham-rounded-bold'} color={'whitesmoke'} fontSize={'larger'}>
                    Peebles are a super-friendly species of beings who live inside BSC, but just like us they get old and die.
                    </Text>
                    <Text color={'white'} fontSize={'larger'}>
                        Represented by an ERC20 token, 1% of all Peebles die every day and the only way to protect your Peebles from aging is to make them breed on farms. Take care of your Peebles, and they will reward you!
                    </Text>
                    <HStack justifyContent={'space-around'}>
                        <Button onClick={makeLogin} isLoading={loading} size={'md'} borderRadius={10} rightIcon={<img src={XIcon} width={40} height={40}/>}>
                            Enter with
                        </Button>
                        <Button onClick={makeGoogleLogin} colorScheme="cyan" isLoading={loading} size={'md'} borderRadius={10} rightIcon={<img src={GoogleIcon} width={40} height={40}/>}>
                            Login with
                        </Button>
                        </HStack>
                </Flex>
                </CardBody>
                </Card>
            </HStack>
            </VStack>
    )
}

export default LoginPage
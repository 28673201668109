import { FARM_V1_ADDRESS } from "../config/constants"
import FarmBuild from '../abis/FarmV1.json'
import { useContractRead, useContractWrite, usePrepareContractWrite } from "wagmi"

export const useFarm = (props: {account: string}) => {
    const {data, isError, isLoading, refetch} = useContractRead({
        address: FARM_V1_ADDRESS,
        abi: FarmBuild.abi,
        functionName: 'farm',
        args: [props.account]
    })
    const d = data as { tokenAmount: number, startTime: number, endTime: number, active: boolean }
    return {data: d, isError, isLoading, refetch}
}

export const useDeposit = (props: {tokenAmount: string, value: string}) => {
    const {config} = usePrepareContractWrite({
        address: FARM_V1_ADDRESS,
        abi: FarmBuild.abi,
        functionName: 'deposit',
        args: [props.tokenAmount],
        value: BigInt(props.value)
    })

    const {data, write, isLoading} = useContractWrite(config)
    return {data, write, isLoading}
}

export const useWithdraw = () => {
    const {config} = usePrepareContractWrite({
        address: FARM_V1_ADDRESS,
        abi: FarmBuild.abi,
        functionName: 'withdraw'
    })

    const {data, write, isLoading} = useContractWrite(config)
    return {data, write, isLoading}
}
import { useEffect } from "react"
import { useAccount, useSwitchNetwork, useWaitForTransaction } from "wagmi"
import { useDeposit } from "../services/FarmV1"
import {useToast, Button} from "@chakra-ui/react";
import { CHAIN_ID } from "../config/constants";

export const ProtectButton = (props: {amount: number, eth: string, balance: number}) => {
    const {connector} = useAccount()
    const {switchNetwork} = useSwitchNetwork()
    const {write: writeDeposit, isLoading: isLoadingDeposit, data: dataDeposit} = useDeposit({tokenAmount: BigInt((props.amount * Math.pow(10, 18))).toString(), value: props.eth})
    const {isLoading: isLoadingDepositTransaction} = useWaitForTransaction({
      hash: dataDeposit?.hash
    })

    const toast = useToast()

    const protect = async () => {
      if(!connector) return
      const chainId = await connector.getChainId()
      if(chainId != CHAIN_ID) {
        if(switchNetwork) switchNetwork(CHAIN_ID)
        toast({
          status: 'warning',
          title: 'Switch to correct network before continue',
          description: 'Peebles living in the bsc',
          duration: 9000,
          isClosable: true
      })
      return
      }
      if (writeDeposit) writeDeposit();
    }

    useEffect(() => {
        if(dataDeposit?.hash) {
            toast({
                status: 'success',
                title: 'Transaction send to blockchain',
                description: 'Your txhash: '+ dataDeposit.hash,
                duration: 9000,
                isClosable: true
            })
        }
      }, [dataDeposit])

      return <Button
      borderRadius={20}
      isDisabled={props.amount < 10 || props.amount > Number((Number(props.balance) / Math.pow(10, 18)).toFixed(2))}
      onClick={protect}
      isLoading={isLoadingDeposit || isLoadingDepositTransaction} w={"100%"} colorScheme="teal">
        Protect for 24h ({(Number(props.eth) / Math.pow(10, 18)).toFixed(4)} BNB)
      </Button>

}
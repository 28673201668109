import { VStack, Text, Button, useToast } from "@chakra-ui/react"
import { useState, useEffect } from "react"
import moment from 'moment-timezone';
import { useWithdraw } from "../services/FarmV1";
import { useAccount, useSwitchNetwork, useWaitForTransaction } from "wagmi";
import { CHAIN_ID } from "../config/constants";

export const WithdrawCounter = (props: {endTime: number, tokenAmount: number, startTime: number}) => {
  const {connector} = useAccount()
  const {switchNetwork} = useSwitchNetwork()
    const [remainingTime, setRemainingTime] = useState('')
    const [earnedValue, setEarnedValue] = useState(0)
    const [isEnded, setIsEnded] = useState(false)
    const {data, isLoading, write} = useWithdraw()
    const {isLoading: isLoadingTransaction} = useWaitForTransaction({
      hash: data?.hash
    })
    const toast = useToast()
    useEffect(() => {
      if(data?.hash) {
        toast({
          status: 'success',
          title: 'Transaction send to blockchain',
          description: 'Your txhash: '+ data.hash,
          duration: 9000,
          isClosable: true
      })
      }
    }, [data])

    useEffect(() => {
        const calcularTempoRestante = () => {
          if(moment().unix() >= Number(props.endTime.toString())) {
            setRemainingTime(`0h 0m 0s`)
            setEarnedValue(((Number(props.tokenAmount.toString()) / Math.pow(10, 18)) * 1.1) * 100)
            setIsEnded(true)
            return
          }
          // Configurar a data-alvo como 15h do dia seguinte no fuso horário GMT -3
          const dataAlvo = moment.unix(Number(props.endTime.toString()));
          const startDate = moment.unix(Number(props.endTime.toString()));
          // Calcular a diferença entre a data atual e a data-alvo
          const diferenca = moment.duration(dataAlvo.diff(moment()));
    
          // Formatando a diferença no formato desejado (horas:minutos:segundos)
          const tempoRestanteFormatado = `${diferenca.hours()}h ${diferenca.minutes()}m ${diferenca.seconds()}s`;
    
          setRemainingTime(tempoRestanteFormatado);

          // calcular ganhos nesse tempo
          const totalEarn = (Number(props.tokenAmount.toString()) / Math.pow(10, 18)) * 0.1
          const earnPerSeconds = totalEarn / 86400
          const momentoAtual = moment();
          const diferencaSegundos = momentoAtual.diff(startDate, 'seconds');
          setEarnedValue((totalEarn - ((diferencaSegundos  * earnPerSeconds) * -1)) * 100)
        };
    
        // Atualizar o tempo restante a cada segundo
        const intervalId = setInterval(calcularTempoRestante, 1000);
    
        // Limpar o intervalo quando o componente for desmontado
        return () => clearInterval(intervalId);
    
      }, []);

      const withdraw = async () => {
        if(!connector) return
        const chainId = await connector.getChainId()
        if(chainId != CHAIN_ID) {
          if(switchNetwork) switchNetwork(CHAIN_ID)
          toast({
            status: 'warning',
            title: 'Switch to correct network before continue',
            description: 'Peebles living in the bsc',
            duration: 9000,
            isClosable: true
        })
        return
        }
        if(write) write()
      }
    return (
        <VStack>
          <Text fontFamily={'gotham-rounded-bold'} color={'yellow'} fontSize={'medium'}>You've already farmed</Text>
          <Text fontFamily={'gotham-rounded-bold'} color={'yellow'} fontSize={'x-large'}>{earnedValue.toFixed(4)} mPEB</Text>
          {isEnded ? <Button 
            borderRadius={20}
            isLoading={isLoading || isLoadingTransaction}
            onClick={withdraw}
            colorScheme="yellow" size={'lg'}>
              Withdraw {earnedValue / 100} PEB
            </Button>
            :
            <Button borderRadius={20}
            isDisabled colorScheme="yellow" size={'lg'}>
              Withdraw in {remainingTime}
            </Button>}
        </VStack>
    )
}
import { Container, Card, CardBody, HStack, Box, Image, VStack, Text, Spinner, CardHeader, CardFooter } from "@chakra-ui/react"
import Logo from '../assets/Unprotected.jpg'
import { usePeeblesBalance } from "../services/Peebles"
import { useAccount } from "wagmi"
import { DebaseCounter } from "./debase-counter"
import { useEffect } from "react"

export const UnprotectedBalance = () => {
    const {address} = useAccount()
    const {data, isLoading, refetch} = usePeeblesBalance({account: address ?? ''})
    
    useEffect(() => {
        const intervalId = setInterval(() => refetch(), 10000)
        return () => clearInterval(intervalId)
    }, [])
    
    return (
        <Container>
            <Card bgColor={'gray.700'} margin={2} boxShadow='dark-lg' p='0' borderRadius={50}>
                <CardHeader >
                    <Text w={'100%'} align={'center'} color={'white'} fontFamily={'gotham-rounded-bold'} fontSize={'large'}> UNPROTECTED balance</Text>
                </CardHeader>
                <CardBody justifyContent={'center'} alignItems={'center'}>
                    <HStack gap={'10px'} justifyContent={'space-around'} wrap={'wrap'}>
                        <Image objectFit={'cover'} src={Logo} borderRadius={5}  w={'10em'} h={'10em'}/>
                    <VStack justifyContent={'flex-start'}>
                    {isLoading ?
                        <Spinner color="white"/>
                        :
                        <Text w={'100%'} align={'center'} color={'white'} fontFamily={'gotham-rounded-bold'} fontSize={'xx-large'}> 
                        {data ? ''+(Number(data) / Math.pow(10, 18)).toFixed(2) : '0'} PEB
                        </Text>
                    }
                                        {!!data && <DebaseCounter />}
                    </VStack>
                    </HStack>
                </CardBody>
            </Card>
        </Container>
    )
}
import {
  Container,
  Card,
  CardBody,
  CardHeader,
  Text,
  HStack,
  Image,
  VStack,
  NumberInput,
  NumberInputField,
  Link,
  Button,
  Spinner,
  useToast,
} from "@chakra-ui/react";
import Logo from "../assets/FarmShield.jpg";
import { useEffect, useState } from "react";
import { useAccount, useWaitForTransaction } from "wagmi";
import {
  useApprove,
  usePeeblesAllowance,
  usePeeblesBalance,
} from "../services/Peebles";
import { ArrowRightIcon } from "@chakra-ui/icons";
import { useDeposit, useFarm } from "../services/FarmV1";
import { FARM_PARAMS, FARM_V1_ADDRESS } from "../config/constants";
import { WithdrawCounter } from "./withdraw-counter";
import { ProtectButton } from "./protect-button";
import { ApproveButton } from "./approve-button";
export const ProtectFarmV1 = () => {
  const { address } = useAccount();
  const { data, isLoading, refetch } = useFarm({ account: address ?? "" });

  useEffect(() => {
    const intervalId = setInterval(() => refetch(), 5000)
    return () => clearInterval(intervalId)
  }, [])

  return (
    <Container>
      <Card
        border={"2px solid teal"}
        bgColor={"gray.700"}
        margin={2}
        boxShadow="dark-lg"
        p="0"
        borderRadius={50}
      >
        <CardHeader>
          <Text fontFamily={"gotham-rounded-bold"} color={"white"}>
            Protect your Peebles from DEATH and earn 10% extra!
            <br/>
            1% of all Peebles die every day. The only way to protect them is by letting them breed ❤
          </Text>
        </CardHeader>
        <CardBody justifyContent={'center'} alignItems={'center'}>
          <HStack wrap={"wrap"} justifyContent={"space-around"} alignItems={'center'}>
            <Image borderRadius={20} w={"10em"} h={"10em"} src={Logo} />
            {isLoading ? (
              <Spinner color={"white"} />
            ) : !data || !data.active ? (
              <ProtectInput address={address} />
            ) : (
              <WithdrawCounter endTime={data.endTime} startTime={data.startTime} tokenAmount={data.tokenAmount}/>
            )}
          </HStack>
        </CardBody>
      </Card>
    </Container>
  );
};



const ProtectInput = (props: { address: string | undefined }) => {
  const { data, refetch } = usePeeblesBalance({
    account: props.address ?? "",
  });
  const {
    data: allowance,
    refetch: refetchAllowance,
  } = usePeeblesAllowance({
    owner: props.address ?? "",
    spender: FARM_V1_ADDRESS,
  });
  const [value, setValue] = useState(100);

  const calculatePrice = () => {
    return (BigInt((value * Math.pow(10, 18))) / BigInt(FARM_PARAMS.TOKEN_PER_ETH)) * BigInt(FARM_PARAMS.FEE_RATE)
  }

  const handleChange = (value: number) => setValue(value);
  
  useEffect(() => {
    const intervalId = setInterval(() => {
      refetch();
      refetchAllowance();
    }, 5000);
    return () => clearInterval(intervalId);
  }, []);


  return (
    <VStack>
      <HStack maxW={"20em"} alignItems={"center"}>
        <NumberInput
          value={value}
          allowMouseWheel
          clampValueOnBlur={false}
          defaultValue={100}
          min={100}
          max={Number((Number(data) / Math.pow(10, 18)).toFixed(2))}
        >
          <Text fontFamily={"gotham-rounded-bold"} color={"white"}>
            You Deposit
          </Text>
          <NumberInputField
            fontFamily={"gotham-rounded-bold"}
            value={value}
            onChange={(e) => handleChange(Number(e.target.value))}
            color={"white"}
          />

          <Text
            fontFamily={"gotham-rounded-bold"}
            w={"100%"}
            align={"right"}
            color={"white"}
          >
            Max{" "}
            <Link
              onClick={() =>
                setValue(Number((Number(data) / Math.pow(10, 18)).toFixed(2)))
              }
              color={"pink"}
            >
              ${Number((Number(data) / Math.pow(10, 18)).toFixed(2))} PEB
            </Link>
          </Text>
        </NumberInput>
        <ArrowRightIcon w={4} h={4} color="white" />
        <NumberInput
          borderColor={"yellow"}
          isDisabled
          value={Number(value * 1.1).toFixed(2)}
          clampValueOnBlur={false}
        >
          <Text fontFamily={"gotham-rounded-bold"} color={"yellow"}>
            You Withdraw
          </Text>
          <NumberInputField
            _disabled={{
              color: "yellow",
              fontcolor: "yellow",
            }}
            fontFamily={"gotham-rounded-bold"}
            color={"white"}
          />
          <Text
            fontFamily={"gotham-rounded-bold"}
            w={"100%"}
            align={"right"}
            color={"white"}
          >
            &#8734;
          </Text>
        </NumberInput>
      </HStack>
      {!allowance || Number(allowance) < Number(value * Math.pow(10, 18)) ? (
        <ApproveButton value={value} balance={data}/>
      ) : (
        <ProtectButton amount={value} eth={calculatePrice().toString()} balance={Number(data)}/>
      )}
    </VStack>
  );
};

import { Badge, Button, Card, CardBody, CardFooter, Container, Flex, HStack, Image, Spinner, Text, VStack, useToast } from "@chakra-ui/react"
import HiLogo from '../assets/EarningsWIthInvite.jpg'
import { EarningsWithInvitation } from "./earnings-with-invitation"
import { useEffect, useState } from "react"
import { doc, getDoc } from "firebase/firestore"
import { auth, firestore, service } from "../firebase"
import { DbUser } from "../types/user"
import { PaymentOrders } from "./payment-orders"
import XIcon from '../assets/xicon.svg'
import { CopyIcon } from "@chakra-ui/icons"
import TGIcon from '../assets/telegramIcon.svg'
import WppIcon from '../assets/whatsappicon.svg'

export const InvitationCode = () => {
    const [inviteCode, setInviteCode] = useState<string>()
    const toast = useToast()
    const getOrCreateInvite = async () => {
        const userUid = auth.currentUser?.uid ?? ""
        const userRef = doc(firestore, 'users', userUid)
        const snap = await getDoc(userRef)
        const data = snap.data() as DbUser
        if(!data.inviteCode) {
            const resp = await service.createUserInvite();
            setInviteCode(resp.data as any)
            return
        }
        setInviteCode(data.inviteCode)
    }

    useEffect(() => {
        getOrCreateInvite()
    }, [])


    return (
        <Container>
        <Card bgColor={'gray.700'} margin={2} overflow={'hidden'} boxShadow='dark-lg' p='0' borderRadius={50}>

            <CardBody>
        <VStack>
        <HStack marginY={5}  spacing={'2'} justifyContent={'center'} wrap={'wrap'}>
        <Image src={HiLogo} w={'10em'} h={'10em'} borderRadius={20}/>

        <Flex direction={'column'} justifyContent={'center'} h={'10em'} maxW={'20em'} borderRadius={10} >
            <Text color={'whitesmoke'} fontSize={'larger'}>
            Get 100 $PEBs for everyone who joins using your invitation code!
            They also get 100 $PEBs!
            </Text>
            {!inviteCode ? 
            <HStack justifyContent={'center'}> <Spinner size='lg' color="white"/> </HStack>
             : 
             <HStack marginTop={5} justifyContent={'center'}>
            <Badge size={'md'} fontFamily={'gotham-rounded-bold'} colorScheme={'teal'} fontSize={'larger'} fontWeight={'900'}>
                {inviteCode}
            </Badge>
            <Button
                        borderRadius={20}
            colorScheme="teal"
            onClick={() => {navigator.clipboard.writeText(inviteCode); toast({status: 'info', title: 'invite copied to clipboard', duration: 3000})}}
            rightIcon={<CopyIcon />} size={'sm'}>
                Copy
            </Button>
            </HStack>
            }  
        </Flex>
        {
            inviteCode &&
            <VStack w={''}>
                <Text fontFamily={'gotham-rounded-bold'} color={'white'} w={'100%'} align={'left'} fontSize={'larger'}>Share on</Text>
            <HStack wrap={'wrap'} justifyContent={'space-between'} w={'100%'}>
        <a target="_blank" href={`https://twitter.com/intent/tweet?text=Use+my+invite+${inviteCode}+at+peeble.xyz+and+EARN+$100+PEB+right+now%0D%0A%40PeebleProtect`}>
        <Button             borderRadius={20}
 variant={'solid'} colorScheme="gray" rightIcon={<Image src={XIcon} w={8} h={8} />} size={'md'}>
                Twitter
            </Button>
            </a>

            <a target="_blank" href={`https://web.whatsapp.com/send?text=Use+my+invite+${inviteCode}+at+peeble.xyz+and+EARN+$100+PEB+right+now%0D%0A%40PeebleProtect`}>
        <Button             borderRadius={20}
variant={'solid'} colorScheme="green" rightIcon={<Image src={WppIcon} w={8} h={8}/>} size={'md'}>
                Whatsapp
            </Button>
            </a>

            <a target="_blank" href={`https://t.me/share/url?url=peeble.xyz&text=Use+my+invite+${inviteCode}+at+peeble.xyz+and+EARN+$100+PEB+right+now%0D%0A%40PeebleProtect`}>
        <Button             borderRadius={20}
variant={'solid'} colorScheme="blue" rightIcon={<Image src={TGIcon} w={8} h={8}/>} size={'md'}>
                Telegram
            </Button>
            </a>
            </HStack>
            </VStack>
        }

    </HStack>

        <EarningsWithInvitation inviteCode={inviteCode} />
    </VStack>
    </CardBody>
    <CardFooter>
        <PaymentOrders />
    </CardFooter>
    </Card>
    </Container>
    )
}
import { Box, Button, Card, CardBody, CardFooter, CardHeader, Container, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerHeader, DrawerOverlay, HStack, Image, Text, VStack } from "@chakra-ui/react"
import { useEffect } from "react"
import { useAccount, useChainId, useConnect } from "wagmi"
import { auth, firestore } from "../firebase"
import { doc, updateDoc } from "firebase/firestore"
import Logo from "../assets/welcome.jpg"
import { CHAIN_ID } from "../config/constants"
import TelegramIcon from '../assets/telegramIcon.svg'
import XIcon from '../assets/xicon.svg'

export const ConnectWalletButton = () => {
    const {address} = useAccount()
    const {connectors, connect} = useConnect()

    useEffect(() => {
        if(address) {
            const userUid = auth.currentUser?.uid
            if(!userUid) return
            const userRef = doc(firestore, 'users', userUid)
            updateDoc(userRef, {lastConnectedAddress: address})
        }
    }, [address])

    if(!address) return (
        <VStack>
            <Drawer
                isOpen={true}
                placement="right"
                onClose={() => {}}
            >
                <DrawerOverlay />
                <DrawerContent bg={'black'}>
                    <DrawerHeader color={'white'}>Connect your wallet:</DrawerHeader>
                    <DrawerBody>
                        <VStack spacing={'2'}>
            {connectors.map((connector) => (
            <Button
            colorScheme="pink"
            variant={'outline'}
            isDisabled={!connector.ready}
            key={connector.id}
            w={'100%'}
            onClick={() => connect({ connector })}
            >{connector.name} {!connector.ready && " (unsupported)"} </Button>
          ))}
          </VStack>
          </DrawerBody>
          </DrawerContent>
          </Drawer>
        </VStack>
    )

    return (
        <Container>
        <Card bgColor={'gray.700'} margin={2} overflow={'hidden'} boxShadow='dark-lg' borderRadius={50}>
        <Image src={Logo} objectFit={'scale-down'}/>

            <CardBody justifyContent={'center'} alignItems={'center'}>
                <HStack wrap={'wrap'} justifyContent={"space-around"} alignItems={'center'}>
                    <VStack>
                <Text fontFamily={'gotham-rounded-bold'} fontSize={'large'} color={'white'}>Welcome {auth.currentUser?.displayName}</Text>
                <Text color={'white'}>Connected with: {`${address.slice(0, 5)}...${address.slice(address.length - 5)}`}</Text>
                </VStack>
                </HStack>
            </CardBody>
            <CardFooter>
            <HStack wrap={'wrap'} justifyContent={'space-evenly'} w={'100%'}>
                    <a target="_blank" href="https://t.me/+bugUTq3-0lg4ZTRk">
                        <Button borderRadius={20} leftIcon={<Image src={TelegramIcon} w={8} h={8}/>} colorScheme={'blue'} size={'md'}>
                            Join on Telegram
                        </Button>
                    </a>
                    <a target="_blank" href="https://twitter.com/PeebleProtect">
                    <Button borderRadius={20} leftIcon={<Image src={XIcon} w={8} h={8}/>} colorScheme={'gray'} size={'md'}>
                        Follow us on X
                    </Button>
                    </a>
                </HStack>
            </CardFooter>
        </Card>
        </Container>
    )
}
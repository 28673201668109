import { VStack, Text, HStack } from "@chakra-ui/react"
import { useState, useEffect } from "react"
import moment from 'moment-timezone';

export const DebaseCounter = () => {
    const [remainingTime, setRemainingTime] = useState('')
    useEffect(() => {
        const calcularTempoRestante = () => {
          // Configurar a data-alvo como 15h do dia seguinte no fuso horário GMT -3
          const dataAlvo = moment().add(1, 'day').set({ hour: 15, minute: 0, second: 0, millisecond: 0 }).tz('America/Sao_Paulo');
    
          // Calcular a diferença entre a data atual e a data-alvo
          const diferenca = moment.duration(dataAlvo.diff(moment()));
    
          // Formatando a diferença no formato desejado (horas:minutos:segundos)
          const tempoRestanteFormatado = `${diferenca.hours()}h ${diferenca.minutes()}m ${diferenca.seconds()}s`;
    
          setRemainingTime(tempoRestanteFormatado);
        };
    
        // Atualizar o tempo restante a cada segundo
        const intervalId = setInterval(calcularTempoRestante, 1000);
    
        // Limpar o intervalo quando o componente for desmontado
        return () => clearInterval(intervalId);
    
      }, []);
    return (
        <VStack borderRadius={20} padding={3} border={'1px solid red'} wrap={'wrap'} justifyContent={'center'}>
            <Text fontFamily={'gotham-rounded-bold'} color={'red'} fontSize={'xx-large'}>{remainingTime}</Text>
            <Text fontFamily={'gotham-rounded-bold'} color={'red'}>10% of your Peebles will die in</Text>
        </VStack>
    )
}
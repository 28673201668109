import { Card, CardHeader, VStack, Text, Divider, TableContainer, Table, TableCaption, Thead, Tr, Th, Td, Tbody, Button } from "@chakra-ui/react"
import { useEffect, useState } from "react"
import { PaymentOrder } from "../types/payment-order"
import { collection, doc, onSnapshot, or, query, where } from "firebase/firestore"
import { auth, firestore } from "../firebase"
import { ClaimOrder } from "./claim-order"

export const PaymentOrders = () => {
    const [orders, setOrders] = useState<PaymentOrder[]>()
    useEffect(() => {
        const ref = collection(firestore, 'payment-orders')
        const q = query(ref, where("userUid", "==", auth.currentUser?.uid))
        const sub = onSnapshot(q, (docs) => {
            if(docs.empty) return
            setOrders(docs.docs.map(d => d.data()) as PaymentOrder[])
        })
    }, [])

    if(!orders) return <></>
    return (
            <VStack w={'100%'}>
                <Text color={'white'} fontSize={'large'} fontFamily={'gotham-rounded-bold'}>My claims</Text>
                <TableContainer>
                    <Table variant={'simple'}>
                        <Thead>
                            <Tr>
                                <Th>Amount</Th>
                                <Th>Action</Th>
                                <Th>Wallet</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {orders?.map(order => {
                                return <Tr key={order.hash}>
                                    <Td color={'white'}>{(Number(order.amount) / Math.pow(10, 18)).toFixed(2)} $PEB</Td>
                                    <Td color={'white'}>{
                                        order.isUsed || order.txHash ?
                                        <a target="_blank" href={`https://bscscan.com/tx/${order.txHash}`}>
                                            <Button borderRadius={20} colorScheme="teal">Check</Button>
                                        </a>
                                        :
                                        <ClaimOrder order={order}/>
                                    }</Td>
                                    <Td color={'white'}>{`${order.address.slice(0, 5)}...${order.address.slice(order.address.length - 5)}`}</Td>
                                </Tr>
                            })}
                        </Tbody>
                    </Table>
                </TableContainer>
            </VStack>
    )
}
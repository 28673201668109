import { Alert, AlertDescription, AlertIcon, AlertTitle, Button, Image, VStack } from "@chakra-ui/react"
import { InvitationCode } from "../components/invitation-code"
import { ConnectWalletButton } from "../components/connect-wallet-button"
import { UnprotectedBalance } from "../components/unprotected-balance"
import { auth } from "../firebase"
import { ProtectFarmV1 } from "../components/protect-farmv1"
import { useEffect } from "react"

const HomePage = () => {





    return (
        <VStack minH={'100vh'} alignContent={'center'} justifyContent={'space-around'}>
            <Alert status="info">
                <AlertIcon />
                <AlertTitle>Peeble BETA - Version 1.12</AlertTitle>
                <AlertDescription>This is a beta version of Peebles world. if you face some issue, please, report to us on telegram</AlertDescription>
            </Alert>
            <ConnectWalletButton />
            <InvitationCode />
            <ProtectFarmV1 />
            <UnprotectedBalance />
            <Button marginY={5} onClick={() => auth.signOut()}>Logout</Button>
        </VStack>
    )
}

export default HomePage